@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

#wrapper{
    @apply bg-gray-50 pb-8
}

.elementor-column-gap-default > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left : 0px!important;
    padding-right : 0px!important;
}

@font-face {
    font-family : "DM sans";
    font-weight : 400;
    src         : url("src/font/DM_Sans/DMSans-Regular.ttf");
}

@font-face {
    font-family : "DM sans";
    font-weight : 500;
    src         : url("src/font/DM_Sans/DMSans-Medium.ttf");
}

@font-face {
    font-family : "DM sans";
    font-weight : bold;
    src         : url("src/font/DM_Sans/DMSans-Bold.ttf");
}


* {
    font-family: "Roboto", sans-serif;
}

.elementor-field{
    background: white;
    border: 2px solid rgb(0,0,0,0.1)!important;
    padding: 10px 15px!important;
    border-radius: 5px!important;
    font-weight: 500;
    width: 100%;
}

.ce-product-description p {
    display: inline-block;
}

#category-description {
    margin-top: 20px ;
}

#category-description p{
    margin: 10px 0;
    display: inline-block;
}

#category-description h1, #category-description h2, #category-description h3 {
    margin-top: 30px;
}

#category-description ul{
    list-style: inside;
}

#category-description img, #category-description video{
    max-width: inherit;
}